import { Accordion } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getProjectDocumentsAPi,
  deleteDocument,
  getProjectUploadedSignedUrl,
} from "../../../services/project.service";
import React from "react";
import Loader from "../../../components/common/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "../../../assets/images/delete.svg";
import InfoGrayIcon from "../../../assets/images/info-gray.svg";
import UploadPrimaryIcon from "../../../assets/images/upload-primary.svg";
import ProjectDocumentUpload from "./DocumentUpload";
import { toast } from "react-toastify";
import { checkAbility } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
interface AddProps {
  id: any;
  onUploadDoc?: () => void;
  viewType?: string;
  projectsData: any;
}
const ProjectDocumentList: React.FC<AddProps> = ({ id, onUploadDoc, viewType="P", projectsData}) => {
  const [documentUploadPopup, setDocumentUploadPopup] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(true);

  const toggleDocumentUpload = (isToggle: boolean = false) => {
    setDocumentUploadPopup(!documentUploadPopup);
    fetchData();
    if (onUploadDoc) {
      onUploadDoc();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    //  setLoading(true);
    return new Promise((resolve, reject) => {
      getProjectDocumentsAPi(id + "?view=true")
        .then((response) => {
          let docData = response.data;
          if(viewType == 'F'){
            docData = docData.filter((obj: { key: string; isFileExists: boolean; }) => !(obj.key === "other" && obj.isFileExists === false));
          }
          setSelectedDocuments(docData);
          setLoading(false);
          resolve(true);
        })
        .catch((error) => {
          setLoading(false);
          reject(error);
        });
    });
  };

  const removeDocument = (docType: string, docId: number) => {
    deleteDocument(docType, id, docId)
      .then((response) => {
        toast(response.msg, { type: toast.TYPE.SUCCESS });
        if (onUploadDoc) {
          onUploadDoc();
        }
        fetchData();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDocumentUploadPopup = (
    docType: string,
    docExits: boolean,
    docId: any = null,
    docLabel: any = null
  ) => {
    if (docExits) {
      removeDocument(docType, docId);
    } else {
      const projectIds: any = {
        id: id,
        docType: docType,
        docLabel: docLabel,
        projectDetail: projectsData
      };
      setSelectionModel(projectIds);
      setDocumentUploadPopup(!documentUploadPopup);
    }
  };

  /**
   * Download Document click handler
   */
  const handleDownloadClick = (docType: string, docId: any = null) => {
    getProjectUploadedSignedUrl(docType, id, docId)
      .then((response: any) => {
        const signedFileUrl = response.data.file;
        // window.open(signedFileUrl, '_blank');
        window.open(signedFileUrl, "_blank");
        //console.log(signedFileUrl);
      })
      .catch((error: any) => {
        toast(error.response.data.message, { type: toast.TYPE.ERROR });
      })
      .finally(() => {});
  };

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="bg-white border-0">
              <Accordion.Header className="bg-white p-0 project-accordion-header ">
                <h3 className="fs-18 mb-0 info-text fw-bold d-inline-block">
                  Documents & Files{" "}
                </h3>
              </Accordion.Header>
              <Accordion.Body className="bg-white p-0 mt-3">
                {selectedDocuments.map((document: any, index: any) => (
                  <div
                    key={`document${index}`}
                    className="d-flex fs-14 mb-2 justify-content-between pb-2"
                  >
                    <div className="">
                      <span className="me-2">
                        {document?.isFileExists ? (
                          <CheckCircleIcon className="fs-20 text-green" />
                        ) : (
                          <img src={InfoGrayIcon} alt={document.label} />
                        )}
                      </span>
                      <span>
                        {document?.isFileExists ? (
                          <button
                            type="button"
                            className="btn btn-link link-text p-0 text-decoration-none"
                            onClick={() =>
                              handleDownloadClick(
                                document.key,                                
                                document?.id
                              )
                            }
                          >
                            {document.label}
                          </button>
                        ) : (
                          document.label
                        )}
                      </span>
                    </div>
                    {viewType === 'P' && checkAbility(
                      PERMISSION_ACCESS.EDIT,
                      PERMISSION_MODULE.PROJECTS
                    ) && projectsData.status != 'cp' && (
                      <button
                        type="button"
                        className="fw-bold bg-white border-0 p-0"
                        onClick={() =>
                          handleDocumentUploadPopup(
                            document.key,
                            document?.isFileExists,
                            document?.id,
                            document?.label
                          )
                        }
                      >
                        {document?.isFileExists ? (
                          <img
                            src={DeleteIcon}
                            alt="Delete document"
                            title="Delete Document"
                          />
                        ) : (
                          <img
                            src={UploadPrimaryIcon}
                            alt="Upload Document"
                            title="Upload Document"
                          />
                        )}
                      </button>
                    )}
                  </div>
                ))}                
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <ProjectDocumentUpload
            isOpen={documentUploadPopup}
            onClose={toggleDocumentUpload}
            projectsData={selectionModel}
          />
        </>
      )}
    </>
  );
};
export default ProjectDocumentList;
