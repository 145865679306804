import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Card } from "react-bootstrap";
import editIcon from "../../../assets/images/formbuilderedit.svg";
import deleteIcon from "../../../assets/images/formbuilderdelete.svg";
import { getContractProjectItems } from "../../../services/project.service";
import Loader from "../../../components/common/Loader";
import ChangeProductItem from "../../Survey/Start_Survey/changeProductItem";
import AcceptRejectPopup from "../../Survey/Start_Survey/acceptRejectProduct";
import ComparativeStudeyForm from "./ComparativeStudeyForm";
import CustomProduct from "../../Warehouse/Purchase-Orders/custom-product";
import {
  currencySymbol,
  surveyProductStatus,
} from "../../../constants/constants";

const ComparativeStudy: React.FC<any> = ({
  contract_id,
  setIsMutation,
  currentTabIsDone,
}) => {
  const [productData, setProductData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [differenceAmount, setDifferenceAmount] = useState(0);
  const [initialTotal, setInitialTotal] = useState(0);
  const [updatedTotal, setUpdatedTotal] = useState(0);
  const [showChangeProduct, setShowChangeProduct] = useState(false);
  const [type, setType] = useState("add");
  const [popupShow, setPopupShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [contractDetails, setContractDetails] = useState<any>([]);
  const [lastVerifyProduct, setLastVerifyProduct] = useState<any>([]);
  const [qtyUpdate, setQtyUpdate] = useState(false);
  const [fromComparativeStudy, setFromComparativeStudy] = useState(true);
  const [calculateDifference, setCalculateDifference] = useState(false);
  const [customProductModel, setCustomProductModel] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getDifferenceAmount(productData);
  }, [productData]);

  const showReject = (index: number) => {
    setSelectedIndex(index);
    setType("reject");
    setPopupShow(true);
  };

  const ChangeOrAddProduct = () => {
    setType("add");
    setShowChangeProduct(true);
    setQtyUpdate(false);
  };
  const fetchData = () => {
    setLoading(true);
    getContractProjectItems(contract_id)
      .then((res) => {
        setProductData(JSON.parse(JSON.stringify(res.data[0])));
        getPreviousProductCount(JSON.parse(JSON.stringify(res.data[0])));
        setLastVerifyProduct(res.data[0]);
        setContractDetails(res.data[1]);
        getDifferenceAmount(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const getPreviousProductCount = (data: any) => {
    let initialAmount = 0;
    data.map((item: any) => {
      if (item.status != 5) {
        initialAmount += item.price * item.qty;
      }
    });
    setInitialTotal(initialAmount);
  };
  const getDifferenceAmount = (data: any) => {
    let updatedAmount = 0;
    //add prince multiply by qty set in initial amount and print multiply by updated_qty set in updated amount
    data.map((item: any) => {
      if (item.status != 0 && item.status != 2 && item.status != 3) {
        updatedAmount += item.price * item.updated_qty;
      }
    });
    console.log('productData>', productData);
    console.log('updatedAmount>>', updatedAmount);
    setUpdatedTotal(updatedAmount);
    setDifferenceAmount(updatedAmount - initialTotal);
    setCalculateDifference(true);
  };
  const closeChangeProduct = () => {
    setShowChangeProduct(false);
  };

  const handleChangeProduct = (index: number) => {
    setType("change");
    setShowChangeProduct(true);
    setSelectedIndex(index);
    setQtyUpdate(true);
  };
  const getTextClass = (status: number) => {
    switch (status) {
      case 5:
        return "text-green";
        break;
      case 4:
        return "text-orange";
        break;
      case 3:
        return "text-orange";
        break;
      case 2:
        return "text-red text-decoration-line-through";
        break;
      case 6:
        return "text-green";
        break;
      case 7:
        return "text-green";
        break;
      default:
        return "";
    }
  };
  const handleClosePopup = () => {
    setPopupShow(false);
  };
  const handleProductAccept = (index: number, type: string) => {
    let productArrValue = [...productData];
    if (type === "accept") {
      productArrValue[index].status = 1; //1  is for approved a product
    } else {
      if (
        productArrValue[index].status ==
          surveyProductStatus.ADDED_FROM_COMPARATIVE ||
        productArrValue[index].status ==
          surveyProductStatus.REPLACED_FROM_COMPARATIVE
      ) {
        const filteredProduct = productArrValue.filter(
          (item: any, key: number) => {
            return key != index;
          }
        );
        setProductData(filteredProduct);
      } else {
        productArrValue[index].status = 2; //1  is for approved a product
        setProductData(productArrValue);
      }
    }
  };

  const opencustomproduct = () => {
    setCustomProductModel(true);
    closeChangeProduct();
  };

  const closeCustomProduct = () => {
    setCustomProductModel(false);
  };

  const handleCustomProducts = (formValues: any, setFieldValue: any) => {
    if (
      formValues.product_qty > 0 &&
      formValues.product_price > 0
    ) {
      let productArrValue = [...productData];
      const fieldDataObj = {
        product_id: null,
        qty: formValues.product_qty,
        price: formValues.product_price,
        amount: Number(
          parseInt(formValues.product_qty) *
            parseFloat(formValues.product_price)
        ),
        product_name: formValues.product_name,
        updated_qty: formValues.product_qty,
        product: {
          name: formValues.product_name,
          qty: formValues.product_qty,
          price: formValues.product_price,
          amount: Number(
            parseInt(formValues.product_qty) *
              parseFloat(formValues.product_price)
          )
        },
        status: 5
      };
      productArrValue.push(fieldDataObj);
      setProductData(productArrValue);
    }
  }

  return (
    <div>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div>
          {showChangeProduct && (
            <ChangeProductItem
              showChangeProduct={showChangeProduct}
              closeChangeProduct={closeChangeProduct}
              type={type}
              productsList={productData}
              setProductItem={setProductData}
              index={selectedIndex}
              contract_id={contract_id}
              qtyUpdate={qtyUpdate}
              fromComparativeStudy={fromComparativeStudy}
              openCustomModal={opencustomproduct}
            />
          )}

          {popupShow && (
            <AcceptRejectPopup
              showPopup={popupShow}
              handleClosePopup={handleClosePopup}
              type={type}
              handleProductAccept={handleProductAccept}
              index={selectedIndex}
              text="Are you sure you want to remove this product?"
            />
          )}

            {customProductModel && (
              <CustomProduct
                isOpen={customProductModel}
                onClose={closeCustomProduct}
                setFieldValue={null}
                handleProducts={handleCustomProducts}
                values={
                  {orderProducts: productData}
               }
              />
            )}

          <div className="">
            <div className="align-items-center d-flex justify-content-between mb-3">
              <div className="fs-24 fw-bold info-text">
                Product Items Comparative Study
              </div>
              <div className="d-flex ">
                <div className="ms-3 fs-14 fw-semibold">
                  <span>
                    {" "}
                    <span className="product-item-tag bg-green"></span>New Item
                  </span>
                </div>
                <div className="ms-3 fs-14 fw-semibold">
                  <span>
                    {" "}
                    <span className="product-item-tag bg-red"></span>Remove
                  </span>
                </div>
                <div className="ms-3 fs-14 fw-semibold">
                  <span>
                    {" "}
                    <span className="product-item-tag bg-orange"></span>Change
                  </span>
                </div>
              </div>
            </div>
            <Row>
              <Col md={6}>
                <Card className="p-3 w-100 pageContentInner mb-3">
                  <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">
                    Contract - Product Item List
                  </h3>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="text-end">Quantity</th>
                          <th className="text-end">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {lastVerifyProduct.length > 0 ? (
                          lastVerifyProduct.map((item: any, index: number) => {
                            if (item.status != 5) {
                              return (
                                <tr key={index}>
                                  <td title={item.product.name}>
                                    <p
                                      className={`text-wrap-ellipsis ${getTextClass(
                                        item.status
                                      )}`}
                                    >
                                      {item.product.name}
                                    </p>
                                  </td>
                                  <td className="text-end">{item.qty}</td>
                                  <td className="text-end">
                                    <p
                                      className={`text-wrap-ellipsis ${getTextClass(
                                        item.status
                                      )}`}
                                    >
                                      £{parseInt(item.price)*parseInt(item.qty)}
                                    </p>
                                  </td>
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No Prodcut Item found
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="fw-bold">Total</td>
                          <td></td>
                          <td className="text-end">
                            {currencySymbol.POUND}
                            {initialTotal.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="p-3 w-100 pageContentInner mb-3">
                  <div className="d-flex justify-content-between">
                    <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">
                      Updated - Product Item List
                    </h3>
                    {!currentTabIsDone && 
                    <span
                      className="ms-2 cursor-pointer"
                      onClick={ChangeOrAddProduct}
                    >
                      <svg
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.49998 1.58325C5.12998 1.58325 1.58331 5.12992 1.58331 9.49992C1.58331 13.8699 5.12998 17.4166 9.49998 17.4166C13.87 17.4166 17.4166 13.8699 17.4166 9.49992C17.4166 5.12992 13.87 1.58325 9.49998 1.58325ZM13.4583 10.2916H10.2916V13.4583H8.70831V10.2916H5.54165V8.70825H8.70831V5.54158H10.2916V8.70825H13.4583V10.2916Z"
                          fill="#21AC96"
                        />
                      </svg>
                    </span>
}
                  </div>
                   <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="text-end">Quantity</th>
                          <th className="text-end">Price</th>
                         {!currentTabIsDone && <th className="text-end">Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {productData.length > 0 ? (
                          productData.map((item: any, index: number) => {
                            if (
                              item.status != surveyProductStatus.PENDING &&
                              item.status != surveyProductStatus.REJECTED && 
                              item.status != surveyProductStatus.REPLACED
                            ) {
                              return (
                                <tr key={index}>
                                  <td title={item.product.name}>
                                    <p
                                      className={`text-wrap-ellipsis ${getTextClass(
                                        item.status
                                      )}`}
                                    >
                                      {item.product.name}
                                     
                                    </p>
                                  </td>
                                  <td className="text-end">
                                     {item.updated_qty}  
                                  </td>
                                  <td className="text-end">£{parseInt(item.price)*parseInt(item.updated_qty)}</td>
                                  {!currentTabIsDone && 
                                  <td className="text-end">
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => {
                                        handleChangeProduct(index);
                                      }}
                                      title="Edit Product Item"
                                    >
                                      <img
                                        src={editIcon}
                                        className="icon-product-size"
                                      />
                                    </a>

                                    <a
                                      href="javascript:void(0)"
                                      onClick={(e) => showReject(index)}
                                      title="Delete Product Item"
                                    >
                                      <img
                                        src={deleteIcon}
                                        className="icon-product-size"
                                      />
                                    </a>
                                  </td>
                            }
                                </tr>
                              );
                            }
                          })
                        ) : (
                          <tr>
                            <td colSpan={3} className="text-center">
                              No Prodcut Item found
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td className="fw-bold">Total</td>
                          <td></td>
                         {!currentTabIsDone && <td></td>}
                          <td className="text-end">
                            {currencySymbol.POUND}
                            {updatedTotal.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Card>
                <p className="d-flex fs-16 mb-3 justify-content-between p-2 bg-primary-opacity rounded-1">
                  <span className="fw-bold">Difference Amount :</span>
                  <span>
                    {currencySymbol.POUND}
                    {`${differenceAmount.toFixed(2)}`}
                  </span>
                </p>
              </Col>
            </Row>
             
            {calculateDifference && (
            <ComparativeStudeyForm
              contract_id={contract_id}
              setIsMutation={setIsMutation}
              contractDetails={contractDetails}
              revisedamount={differenceAmount}
              currentTabIsDone={currentTabIsDone}
              productData={productData}
              updatedTotal={updatedTotal}
            />
          )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparativeStudy;
