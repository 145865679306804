import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  DataGrid,
  GridColDef,
  GridPaginationModel,
  GridRowId,
   GridFilterModel,
  GridSortModel,
  GridActionsCellItem
} from "@mui/x-data-grid";
import ViewIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader";
 
import {
   getEmployeeList,
 } from "../../services/employeeService";
 import { Card } from "react-bootstrap";
 import { pageSetting, pageSizeModel } from "../../constants/constants";
import {   getTagClass } from "../../utility/common";
 
import ExportData from "./exportData";
 

const ArchiveEmployeeList: React.FC = () => {
  const [data, setData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const mapPageToNextCursor = React.useRef<{ [page: number]: GridRowId }>({});
  const [page, setPage] = useState(pageSetting.defaultPage);
  const [limit, setLimit] = useState(pageSetting.defaultLimt);
  const [search, setSearch] = useState({});
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [isMutation, setIsMutation] = useState(false);
   const [exportPopup,setExportPopup] = useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: page,
    pageSize: limit,
  });

  const defaultParams = {
    page: page,
    limit: limit,
    sortColumn: sortColumn,
    sortDirection: sortDirection,
    type:'archive',
    ...(search ? { search } : {}),
  };

  const fetchData = (defaultParams: any) => {
    getEmployeeList(defaultParams)
      .then((response) => {
        setTotalRow(response.data.totalResults);
        setData(response.data?.resultSet);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleExportPopup = () => {
    setExportPopup(!exportPopup);
  }

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    if (filterModel && filterModel.items[0] && filterModel.items[0].value) {
      setSearch({ filterModel: { ...filterModel } });
    } else {
      setSearch({});
    }
  }, []);

  const handleViewClick = async (id: GridRowId) => {
    navigate(`/employees/documents/${id}/`);
  };

  const columns: GridColDef[] = [
    {
      field: "$employeeDetails.employment_id$",
      headerName: "Employee ID",
      flex: 1,
      sortable: true,
      hideable: false,
     
      renderCell(params) {
        return <span>{params.row.employeeDetails.employment_id}</span>;
      },
    },

    { field: "first_name", headerName: "First Name", flex: 1, sortable: true },
    { field: "last_name", headerName: "Family Name", flex: 1, sortable: true },
    /* { field: "email", headerName: "Email", flex: 2, sortable: true }, */
    {
      field: "$employeeDetails.personal_email$",
      headerName: "Personal Email",
      flex: 2,
      sortable: true,
      renderCell(params) {
        return (
          <span title={params.row.employeeDetails.personal_email}>
            {params.row.employeeDetails.personal_email}
          </span>
        );
      },
    },
    {
          field: "actions",
          type: "actions",
          headerName: "Action",
          flex: 0.5,
          cellClassName: "actions",
          getActions: (params) => {
            return (
              [
                <GridActionsCellItem
                  label="View Leave"
                  onClick={(event) => handleViewClick(params.id)}
                  showInMenu={false}
                  className="text-primary"
                  icon={<ViewIcon />}
                />,
              ]
            )
            
        },
        },
  ];

   

  const handleSortModelChange = React.useCallback((sortModel: any) => {
    // Here you save the data you need from the sort model
    if (sortModel[0] && sortModel[0].field && sortModel[0].sort) {
      setSortColumn(sortModel[0].field);
      setSortDirection(sortModel[0].sort);
    }
  }, []);
  const handlePaginationModelChange = (
    newPaginationModel: GridPaginationModel
  ) => {
    setPage(newPaginationModel.page + 1);
    setLimit(newPaginationModel.pageSize);
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    fetchData(defaultParams);
  }, [page, paginationModel, isMutation, search, sortColumn, sortDirection]);

 
  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
          {exportPopup && (
            <ExportData
              isOpen={exportPopup}
              onClose={toggleExportPopup}
              exportType="employee"
              label="Export to Excel"
              text="Export to Excel"
            />
          )}
          <div className="pageHeader px-3 py-2 my-1">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="fw-bold h4 my-2">Archive Employees</h1>
              </div>
              <div>
             
                 
              </div>
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent px-3 pb-3">
            <Card className="  w-100 pageContentInner">
              <div className="d-flex flex-column rounded-0 dataGridListMain">
                <DataGrid
                  rows={data}
                  {...data}
                  columns={columns}
                  rowCount={totalRow}
                  paginationMode="server"
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  filterMode="server"
                  onFilterModelChange={onFilterChange}
                  onSortModelChange={handleSortModelChange}
                  loading={loading}
                  sortingMode="server"
                  pageSizeOptions={pageSizeModel}
                  className="border-0 rounded-2"
                  
                />
                
                
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ArchiveEmployeeList;
