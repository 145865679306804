import moment from 'moment';
import { axios } from "../services/axios";
import {
  PERMISSION_LIST,
  EMPLOYEE_LIST,
  REPORTING_MANAGER_EMPLOYEE_LIST,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  ROLE_DELETE,
  UPLOAD_EMPLOYEE_IMAGES,
  UPDATE_EMPLOYEE_STATUS,
  GET_EMPLOYEE_INDUCTION_LIST,EMPLOYEE_INDUCTION_ADD,EMPLOYEE_INDUCTION_APPROVE,GET_EMPLOYEE_INDUCTION_DETAIL,EMPLOYEE_INDUCTION_EDIT,
  GET_EMPLOYEE_EMAIL_LIST,
  SEND_EMAIL_TEMPLATE,
  SENT_EMAIL_TEMPLATE_LIST,
  GET_SENT_EMAIL_TEMPLATE,
  ALLOCATED_ASSET_LIST,
  EMPLOYEE_MEETINGS,
  MANAGER_MEETINGS,
  CREATE_EMPLOYEE_MEETING,
  GET_EMPLOYEE_MEETING,
  UPDATE_EMPLOYEE_MEETING,
  EMPLOYEE_DOC_LIST,
  EMPLOYEE_DOC_REQUEST_ADD,
  EMPLOYEE_DOC_REQUEST_DETAIL,
  EMPLOYEE_DOC_REQUEST_EDIT,
  EMPLOYEE_DOC_UPLOAD,
  EMPLOYEE_DOC_SENDTOHR_ADD,
  EMPLOYEE_DOC_SENDTOHR_EDIT,
  EMPLOYEE_DOC_GET_DOWNLOAD_URL,
  EMPLOYEE_DOC_DELETE,
  EMPLOYEE_DOC_APPROVE,
  GET_MEETING_NOTES_QUESTIONS,
  FILL_NOTES,
  EMPLOYEE_DASHBOARD,
  PROFILE_CHANGE_REQUEST_ACTION,
  GET_EMPLOYEE_PROFILE_UPDATE_REQUEST,
  EMPLOYEE_EXPORT,
  EMPLOYEE_DELETE
  

} from "../constants/paths";
import datetimeFormats, {
  emailTemplateReplacableKeywords,
  Employee,
  Reporting_Manager
} from "../constants/constants";
export type employeeEmailsListDTO = {
  id: string | number;
  email: string;
}

export type employeeListDTO = {
  id: string | number;
  full_name: string;
  email: string;
  employeeDetails: object;
}

export interface yearType{ key: number; value: number };

export interface managerList {
  id:string;
  name:string;
}


export interface meetingTypes{
  'one_on_one': boolean;
  'appraisal': boolean;
  'other': boolean;
};


export const getEmployeeList = (formData: any): Promise<any> => {
  return axios.get(EMPLOYEE_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
      ...(formData.type ? { type : formData.type} : {})
    },
  });
};

export const getReportingManagerEmployeeList = (formData: any): Promise<any> => {
  return axios.get(REPORTING_MANAGER_EMPLOYEE_LIST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

export const getPermission = (): Promise<any> => {
  return axios.get(PERMISSION_LIST);
};

export const createEmployeeApi = (data: any): Promise<any> => {
  const transformData = {
    ...data,
    designation: data?.designation?.key,
    department: data?.department?.key,
    employee_type: data?.employee_type?.key,
    keen_relation1: data?.keen_relation1?.key,
    keen_relation2: data?.keen_relation2?.key,    
    reporting_manager: data?.reporting_manager?.id, 
    employment_status : data?.employment_status?.key,
    status : data?.status==true?'active':'inactive',
    role : String(data?.role?.key)
  };
  
  return axios.post(CREATE_EMPLOYEE, transformData);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getEmployeeDetailsApi = (id: string): Promise<any> => {
  return axios.get(EMPLOYEE_LIST + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Employee details
 */
export const getReportingManagerEmployeeDetailsApi = (id: string): Promise<any> => {
  return axios.get(REPORTING_MANAGER_EMPLOYEE_LIST + "/" + id);
};

/**
 * @param {id: string, formData: FormData } params
 * @returns Promise<any>
 */
export const updateEmployeeApi = (id: string, data: any): Promise<any> => {
  const transformData = {
    ...data,
    designation: data?.designation?.key,
    department: data?.department?.key,
    employee_type: data?.employee_type?.key,
    keen_relation1: data?.keen_relation1?.key,
    keen_relation2: data?.keen_relation2?.key,    
    reporting_manager: data?.reporting_manager?.id, 
    employment_status : data?.employment_status?.key,
    status : data?.status==true?'active':'inactive',
    role : String(data?.role?.key)
  };
  return axios.put(UPDATE_EMPLOYEE + "/" + id, transformData);
};

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteRoleApi = (id: string | number | null): Promise<any> => {
  return axios.delete(ROLE_DELETE + "/" + id);
};





export const uploadEmployeeImageApi = (data:any): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axios.post(`${UPLOAD_EMPLOYEE_IMAGES}`, data, config);
};

export const updateEmployeeStatusApi =(id: string, data: any): Promise<any> => {
  const transformData = {
    status : data.status=='active' ? 'inactive':'active'
  };
  return axios.patch(UPDATE_EMPLOYEE_STATUS + "/" + id, transformData);
};

//Get List of inductions of employee
export const getEmployeeInductionList = (employeeID: number | string, formData: any): Promise<any> => {
  return axios.get(`${GET_EMPLOYEE_INDUCTION_LIST}/${employeeID}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

// save employee induction 
export const saveEmployeeInduction = (employeeID: number | string,data: any): Promise<any> => {
  const transformData = {
    ...data,
    manager_id: data?.manager_id?.key
  };
  delete transformData.complete_status;
  
  return axios.post(`${EMPLOYEE_INDUCTION_ADD}/${employeeID}`, transformData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// save employee induction 
export const updateEmployeeInduction = (inductionId: number | string,employeeID: number | string,data: any): Promise<any> => {
  const transformData = {
    ...data,
    manager_id: data?.manager_id?.key,
    status: data?.complete_status ? 1 : 0
  };
  delete transformData.complete_status;
  
  return axios.put(`${EMPLOYEE_INDUCTION_EDIT}/${employeeID}/${inductionId}`, transformData ,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const updateEmployeeInductionApproveStatus =(inductionId : string | number,employeeID: number | string, data: any): Promise<any> => {
  const transformData = {
    is_approved : data.is_approved
  };
  return axios.patch(`${EMPLOYEE_INDUCTION_APPROVE}/${employeeID}/${inductionId}`, transformData);
};



// Get list of emails of all employees
export const getEmployeeInductionDetail = (inductionId : string | number,employeeID: number | string): Promise<any> => {
  return axios.get(`${GET_EMPLOYEE_INDUCTION_DETAIL}/${employeeID}/${inductionId}`);
};

// Get list of emails of all employees
export const getEmployeeEmailList = (id?: string | number): Promise<any> => {
  let path = GET_EMPLOYEE_EMAIL_LIST;
  if(id){
    path = `${path}/${id}`;
  }
  
  return axios.get(path);
  
};

// Send email template to employee
export const sendEmailTemplate = (data: any): Promise<any> => {
  return axios.post(SEND_EMAIL_TEMPLATE, data);
};

// Get sent email list to employee
export const sentEmailTemplateList = (employeeID: number | string, formData: any): Promise<any> => {
  return axios.get(`${SENT_EMAIL_TEMPLATE_LIST}/${employeeID}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

// Get detail of sent email to employee
export const getSentEmailTemplate = (id : string | number): Promise<any> => {
  return axios.get(`${GET_SENT_EMAIL_TEMPLATE}/${id}`);
};

// This function replace the fixed set of keywords which should be replaced with actual values in email template body
export const emailTemplateKeywordsReplace = (emailBody: string, employeeData: any, user: any) => {
  let replacedEmailBody = emailBody;

  Object.entries(emailTemplateReplacableKeywords).forEach(([key, keyword]) => {
    switch (key) {
      case 'recipient_name':
        replacedEmailBody = replacedEmailBody.replace(new RegExp(keyword, 'g'), employeeData.full_name);
        break;
      
      case 'sender_name':
        replacedEmailBody = replacedEmailBody.replace(new RegExp(keyword, 'g'), user.user.full_name);
        break;
      
      case 'recipient_address':
        replacedEmailBody = replacedEmailBody.replace(new RegExp(keyword, 'g'), `${employeeData.employeeDetails.address}, ${employeeData.employeeDetails.city}, ${employeeData.employeeDetails.state}`);
        break;
    
      case 'recipient_postcode':
        replacedEmailBody = replacedEmailBody.replace(new RegExp(keyword, 'g'), employeeData.employeeDetails.postal_code);
        break;

      case 'sender_designation':
        let designation = '';
        if(user.user.employeeDetails !== null){
            designation = user.user.employeeDetails.designation;
        }
        replacedEmailBody = replacedEmailBody.replace(new RegExp(keyword, 'g'), designation);
        break;

      case 'date':
        replacedEmailBody = replacedEmailBody.replace(new RegExp(keyword, 'g'), moment().format(datetimeFormats.dateFormats.slashSeparatedDate));
        break;
    
      default:
        break;
    }
  });

  return replacedEmailBody;

};

// Get allocated list
export const allocatedAssetsList = (employeeID: number | string, formData: any): Promise<any> => {
  return axios.get(`${ALLOCATED_ASSET_LIST}/${employeeID}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

// Get list of Document of all employees
export const getEmployeeDocumentList = (employeeID: number | string, docType: number | string, formData: any, fromArchive: boolean = false): Promise<any> => {
  return axios.get(`${EMPLOYEE_DOC_LIST}/${docType}/${employeeID}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      fromArchive,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

// Get employee meetings
export const employeeMeetingsList = (employeeID: number | string, meetingType: string, year: number,formData: any): Promise<any> => {
  return axios.get(`${EMPLOYEE_MEETINGS}/${meetingType}/${year}/${employeeID}`, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

// Get employee meetings
export const managerMeetingsList = (managerId: number | string, meetingType: string, year: number, formData: any, employeeId: number | string | null = null): Promise<any> => {
  let managerMeetingsRequest = `${MANAGER_MEETINGS}/${meetingType}/${year}/${managerId}`;
  if(employeeId){
    managerMeetingsRequest = `${managerMeetingsRequest}/${employeeId}`;
  }
  return axios.get(managerMeetingsRequest, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

// Save employee meeting 
export const createEmployeeMeeting = (employeeID: number | string,data: any): Promise<any> => {
  const transformData = {
    ...data,
    manager_id: data?.manager_id?.id,
    leave_id: data?.leave_id?.key,
  };
  delete transformData.complete;
  //delete transformData.file;
  delete transformData.filename;
  delete transformData.notes;
  delete transformData.file;
  
  return axios.post(`${CREATE_EMPLOYEE_MEETING}/${employeeID}`, transformData,{
    
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    
  });
};

// Get employee meeting detail
export const getEmployeeMeetingDetail = (meetingId : string | number): Promise<any> => {
  return axios.get(`${GET_EMPLOYEE_MEETING}/${meetingId}`);
};

// Update employee meeting 
export const updateEmployeeMeeting = (meetingId: number | string,data: any): Promise<any> => {
  const transformData = {
    ...data,
    leave_id: data?.leave_id?.key,
    manager_id: data?.manager_id?.id,
    complete: data?.complete ? 1 : 0
  };
  delete transformData.filename;
  
  return axios.put(`${UPDATE_EMPLOYEE_MEETING}/${meetingId}`, transformData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  });
};

// Fill notes 
export const fillNotes = (meetingId: number | string | null | undefined, data: any): Promise<any> => {
  const transformData = {
    ...data,
    //manager_id: data?.manager_id?.key
  };
  
  return axios.post(`${FILL_NOTES}/${meetingId}`, transformData);
};

// Get meeting notes questions
export const meetingNotesQuestions = (): Promise<any> => {
  return axios.get(GET_MEETING_NOTES_QUESTIONS);
};

// Delete employee meeting
export const deleteEmployeeMeeting = (id: string | number | null): Promise<any> => {
  return axios.delete(GET_EMPLOYEE_MEETING + "/" + id);
};
// Send employee document request to employee from hr
export const saveEmployeeDocumentRequest = (employeeID: number | string,data: any): Promise<any> => {
  return axios.post(`${EMPLOYEE_DOC_REQUEST_ADD}/${employeeID}`, data);
};

// get employee document request to employee from hr
export const getEmployeeDocumentRequestDetail = (employeeID: number | string,docId: number | string): Promise<any> => {
  return axios.get(`${EMPLOYEE_DOC_REQUEST_DETAIL}/${employeeID}/${docId}`);
};
// update employee document request to employee from hr
export const updateEmployeeDocumentRequest = (docId: number | string, employeeID: number | string,data: any): Promise<any> => {
  return axios.put(`${EMPLOYEE_DOC_REQUEST_EDIT}/${employeeID}/${docId}`, data);
};
// upload employee document send to hr
export const uploadEmployeeDocument = (employeeID: number | string, data: any, documentId: string | number | null | undefined = undefined): Promise<any> => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const apiUrl = documentId ? `${EMPLOYEE_DOC_UPLOAD}/${employeeID}/${documentId}` : `${EMPLOYEE_DOC_UPLOAD}/${employeeID}`;
  return axios.post(apiUrl, data, config);
};


// save employee documents send to hr
export const saveEmployeeDocumentSendToHr = (employeeID: number | string,data: any): Promise<any> => {
  const transformData = {
   ...data
  };
  if(!transformData.description){
    delete transformData.description
  }
  if(!transformData.file){
    delete transformData.file
  }
  return axios.post(`${EMPLOYEE_DOC_SENDTOHR_ADD}/${employeeID}`, transformData);
};

// update employee documents send to hr
export const updateEmployeeDocumentSendToHr = (docId: number | string, employeeID: number | string,data: any): Promise<any> => {
  return axios.put(`${EMPLOYEE_DOC_SENDTOHR_EDIT}/${employeeID}/${docId}`, data);
};

// get employee documents send to hr
export const getEmployeeUploadedDocument = (docId: number | string, employeeID: number | string): Promise<any> => {
  return axios.get(`${EMPLOYEE_DOC_GET_DOWNLOAD_URL}/${employeeID}/${docId}`);
};
// delete employee documents 
export const deleteEmployeeDocument = (docId: string | number | null, employeeID: number | string): Promise<any> => {
  return axios.get(`${EMPLOYEE_DOC_DELETE}/${employeeID}/${docId}`);
};
// Approve Document By Hr
export const updateEmployeeDocumentApproveStatus =(docId : string | number,employeeID: number | string, data: any): Promise<any> => {
  const transformData = {
    is_approved : data.is_approved
  };
  return axios.patch(`${EMPLOYEE_DOC_APPROVE}/${employeeID}/${docId}`, transformData);
};

//get employee Dashboard data
export const getEmployeeDashboardData = (): Promise<any> => {
  return axios.get(`${EMPLOYEE_DASHBOARD}/get-dashboard`);
}

//get employee Annoucement Alerts
export const getAnnoucementDashboardData = (): Promise<any> => {
  return axios.get(`${EMPLOYEE_DASHBOARD}/get-announcements`);
}

//get employee Alerts
export const getEmployeeDashboardAlerts = (): Promise<any> => {
  return axios.get(`${EMPLOYEE_DASHBOARD}/get-alerts`);
}

//get employee Annoucement Alerts
export const markReadAnnoucementDashboardAlert = (annoucementId : string | number | null | undefined): Promise<any> => {
  return axios.patch(`${EMPLOYEE_DASHBOARD}/marks-read-announcement/${annoucementId}`);
}

//get employee Dashboard data
export const getEmployeeTodayTask = (): Promise<any> => {
  return axios.get(`${EMPLOYEE_DASHBOARD}/get-today-task`);
}

//get in progress task of employee
 export const getInProgressTask = (dateRange?:string): Promise<any> => {
  return axios.get(`${EMPLOYEE_DASHBOARD}/get-inprogress-task`, { params: { dateRange } });
}


/**
 * @param {id: string } params
 * @returns Employee update request API
 */
export const getProfileUpdateRequestList = (formData: any): Promise<any> => {
  return axios.get(GET_EMPLOYEE_PROFILE_UPDATE_REQUEST, {
    params: {
      page: formData.page,
      pageSize: formData.limit,
      sortColumn: formData.sortColumn,
      sortDirection: formData.sortDirection,
      ...(formData.search ? { search: formData.search } : {}),
    },
  });
};

/**
 * @param {id: string } params
 * @returns Employee update request API
 */
export const getProfileUpdateRequest = (id: string): Promise<any> => {
  return axios.get(GET_EMPLOYEE_PROFILE_UPDATE_REQUEST + "/" + id);
};

/**
 * @param {id: string } params
 * @returns Profile change request approve/reject
 */
export const profileChangeRequestAction =(id : string | number | null, data: any = {}): Promise<any> => {
  if(data.action === '1'){
    
    let transformData = JSON.parse(JSON.stringify(data));
    transformData.Kin_Details.keen_relation1 = data.Kin_Details?.keen_relation1?.key;
    transformData.Kin_Details.keen_relation2 = data.Kin_Details?.keen_relation2?.key;
    delete transformData.action;
    delete transformData.Employ_details?.user_photo_url;

    // If change request have user_photo, then either it will be same as user request which will be in string or it might be another which will be a file.
    if(transformData.Employ_details?.user_photo){
      if(typeof transformData.Employ_details?.user_photo === 'string'){ 
        transformData = {
          data: JSON.stringify(transformData),
          action: data.action
        };
      }else{ // If it is a new file uploaded by approver, we need to delete that from transformData and pass it as separate parameter as we are sending data in JSON format 
        delete transformData.Employ_details.user_photo; 

        transformData = {
          data: JSON.stringify(transformData),
          action: data.action,
          user_photo: data.Employ_details.user_photo
        };
      }
    }else{
      transformData = {
        data: JSON.stringify(transformData),
        action: data.action
      };
    }

    return axios.post(`${PROFILE_CHANGE_REQUEST_ACTION}/${id}`, transformData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }else{
    return axios.post(`${PROFILE_CHANGE_REQUEST_ACTION}/${id}`, data);
  }
};



export const exportEmployeeListApi = (params:any) : Promise<any> => {
  return axios.get(EMPLOYEE_EXPORT,{params,responseType: 'arraybuffer',});
}

/**
 * @param {id: string } params
 * @returns Promise<any>
 */
export const deleteEmployee = (id: string | number | null): Promise<any> => {
  return axios.delete(EMPLOYEE_DELETE + "/" + id);
};



