import { Accordion, Card, Col, Row } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getProjectDocumentsAPi,
  deleteDocument,
  getProjectUploadedSignedUrl,
} from "../../../services/project.service";
import React from "react";
import Loader from "../../../components/common/Loader";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "../../../assets/images/delete.svg";
import InfoGrayIcon from "../../../assets/images/info-gray.svg";
import UploadPrimaryIcon from "../../../assets/images/upload-primary.svg";
import ProjectDocumentUpload from "./DocumentUpload";
import { toast } from "react-toastify";
import { checkAbility, getLocalDateFormat } from "../../../utility/common";
import {
  PERMISSION_ACCESS,
  PERMISSION_MODULE,
} from "../../../constants/permissionUser";
import DraftOrders from "../../../assets/images/draft_orders.svg";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Today from "../../../assets/images/today.svg";
import moment from "moment";
import constants from "../../../constants/constants";
interface AddProps {
  id: any;
  projectsData: any;
}
const ProjectReleventDetailsAndDates: React.FC<AddProps> = ({ id, projectsData}) => {
  const [documentUploadPopup, setDocumentUploadPopup] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [loading, setLoading] = useState(false);

  const projectStartDate = projectsData?.installation_project?.project_start_date ?? projectsData?.projectDetails?.planned_start_date;;

  /* useEffect(() => {
    console.log('projectsData?.projectDetails>>', projectsData?.projectDetails?.planned_start_date)
    projectsData?.projectDetails?.planned_start_date
    projectsData?.projectDetails?.planned_completion_date
  }, [projectsData]) */
 
  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <>
        {/* { JSON.stringify(projectsData)} */}
           <Card className="col-md-6 p-3 w-100 pageContentInner mb-3">
                <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">
                  Project Relevent Detail{" "}
                  {
                    checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.PROJECTS) && projectsData.status != 'cp' && (
                      <span className="ms-2">
                        <Link to={`/projects/edit/${id}`}>
                          <img src={DraftOrders} />
                        </Link>
                      </span>
                    )
                  }
                  <span className="float-end">
                  <Link to="/planner" state={{project_id:id}}> <CalendarMonthIcon className="fs-20 text-primary" /></Link>
                  </span>
                </h3>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Project Type</span> 
                  <span className="fw-bold ">{ projectsData?.project_type?.name}</span>
                </div>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>CDM Completed & Filed</span> 
                  <span className="fw-bold ">{ projectsData?.projectDetails?.is_cdm_completed ? 'Yes' : "No"}</span>
                </div>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Scheduled Days  to Complete</span> 
                  <span className="fw-bold ">{
                   (projectsData?.projectDetails?.planned_start_date &&
                    projectsData?.projectDetails?.planned_completion_date)
                      ? moment(projectsData?.projectDetails?.planned_completion_date).diff(
                          projectsData?.projectDetails?.planned_start_date,
                          "days"
                        ) + 1
                      : "-"
                    }</span>
                </div>

                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Actual Days to Complete</span> 
                  <span className="fw-bold ">
                    {
                   (projectsData?.installation_project?.completed_on === null ? "0 days" : 
                    (
                      projectStartDate && projectsData?.installation_project?.completed_on)
                      ? moment(projectsData?.installation_project?.completed_on).diff(
                        projectStartDate,
                          "days"
                        ) + 1
                      : "-"
                    )
                    }</span>
                </div>

                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Completion Payment Collected</span> 
                  <span className="fw-bold ">{ projectsData?.projectDetails?.is_completion_payment_collected ? 'Yes' : "No"}</span>
                </div>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Days Since Order Date</span> 
                  <span className="fw-bold ">{moment().diff(projectsData?.order_date, "days")} Days</span>
                </div>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Installation Date Confirmed</span> 
                  <span className="fw-bold ">{ projectsData?.projectDetails?.is_install_date_confirmed ? 'Yes' : "No"}</span>
                </div>
                {
                  projectsData?.projectDetails?.installation_confirm_date && 
                  <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                    <span>Installation Confirmed Date</span> 
                    <span className="fw-bold ">{ getLocalDateFormat(projectsData.projectDetails.installation_confirm_date)}</span>
                  </div>
                }
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2 border-bottom">
                  <span>Interim Payment ready for collection </span> 
                  <span className="fw-bold ">{ projectsData?.projectDetails?.is_interim_payment_ready ? 'Yes' : "No"}</span>
                </div>
                <div className="d-flex fs-14 mb-2 justify-content-between pb-2">
                  <span>Warranty Certificate Logged & Posted</span> 
                  <span className="fw-bold ">{ projectsData?.projectDetails?.is_warranty_cert_posted ? 'Yes' : "No"}</span>
                </div>
                
              </Card>
              <Card className="col-md-4 p-3 w-100 pageContentInner mb-3">
                <h3 className="fs-18 info-text fw-bold d-inline-block mb-3">Important Dates</h3>
                <Row>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Contract Posted</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.contract_posted_date ? getLocalDateFormat(projectsData?.projectDetails?.contract_posted_date): "-"}</div>
                    </div>
                    
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Contract Received</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.contract_received_date ? getLocalDateFormat(projectsData?.projectDetails?.contract_received_date): "-"}</div>
                    </div>
                    
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Planned Survey Date</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.survey_plan_date ? getLocalDateFormat(projectsData?.projectDetails?.survey_plan_date): "-"}</div>
                    </div>
                    
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Date Survey Completed</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.survey_complete_date ? getLocalDateFormat(projectsData?.projectDetails?.survey_complete_date): "-"}</div>
                    </div>
                    
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Planned Start Date</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.planned_start_date ? getLocalDateFormat(projectsData?.projectDetails?.planned_start_date): "-"}</div>
                    </div>
                    
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Planned Completion Date</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.planned_completion_date ? getLocalDateFormat(projectsData?.projectDetails?.planned_completion_date): "-"}</div>
                    </div>
                    
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Warranty Expiry Date</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.warranty_expiry_date ? getLocalDateFormat(projectsData?.projectDetails?.warranty_expiry_date): "-"}</div>
                    </div>
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Confirmation of Delivery Call</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.delivery_call_date ? getLocalDateFormat(projectsData?.projectDetails?.delivery_call_date): "-"}</div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Mid Installation Call</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.mid_installation_call_date ? getLocalDateFormat(projectsData?.projectDetails?.mid_installation_call_date): "-"}</div>
                    </div>
                  </Col>
                  <Col className="col-md-6 d-flex mb-3 align-items-center">
                    <div className="me-2"><img src={Today}/></div>
                    <div className="">
                      <label className="control-label fs-14">Post Installation Review Call</label>
                      <div className="fs-14 fw-bold mb-0">{projectsData?.projectDetails?.post_installation_review_call_date ? getLocalDateFormat(projectsData?.projectDetails?.post_installation_review_call_date): "-"}</div>
                    </div>
                  </Col>
                </Row>
              </Card>
        </>
      )}
    </>
  );
};
export default ProjectReleventDetailsAndDates;
