import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import { Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getSurveyTemplateAPI,
  updateSurveyTemplateAPI,
} from "../../../services/survey/survey.service";
import moment from "moment";
import * as Yup from "yup";
import { MAXIMUM_255_CHAR } from "../../../validations/ValidationErrors";
import TypeText from "./typeText";
import TypeDropdown from "./typeDropdown";
import TypeMediaUpload from "./typeMediaUpload";
import TypeRangeSlider from "./typeRangeSlider";
import TypeRadioButton from "./typeRadioButton";
import { PropertyField, ValidType } from "./surveyInterface";
import SignOff from "./signOff";
import CompareAndFinish from "./compareAndFinish";
import { toast } from "react-toastify";

const StartSurvey: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const [templateData, setTemplateData] = useState<any>({});
  const [tempSteps, setTempSteps] = useState<any>(1);
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [conditions, setConditions] = useState<any>([]);
  const location = useLocation();
  const { survey_id } = location.state;

  const navigate = useNavigate();
  useEffect(() => {
    if (!survey_id) {
      navigate("/survey");
    }
    fetchData();
  }, []);

  const fetchData = () => {
    getSurveyTemplateAPI(survey_id)
      .then((response: any) => {
        setData(response.data);
        setTemplateData(response.data.template[0]);
        setConditions(response.data.template[1]);
        setActiveStep(response.data.active_step);
        setTempSteps(response.data.active_step);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getInitialValues = (subSection: any[]) => {
    const initialValueArr: { [key: string]: string } = {}; // Initialize as an object
    subSection.forEach((subItem: any) => {
      subItem.fields.forEach((valid: ValidType) => {
        initialValueArr[valid.name] = (
          valid.value ? valid.value : valid.default_value ? valid.default_value :""
        ) as string;
      });
    });

    return initialValueArr;
  };

  const getValidationSchema = (subSection: any[]) => {
    let schemaObject: { [key: string]: Yup.AnySchema } = {}; // Initialize as an object
    subSection.forEach((subItem: any) => {
      subItem.fields.forEach((element: any) => {
        //  for (const element of fieldValues) {
        const propertyName = element.name;
        const labelName = element.title;
        if (element.visibility && element.require) {
          if (element.type === "text") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .trim()
              .max(255, MAXIMUM_255_CHAR)
              .label(labelName);
          }
          if (element.type === "dropdown") {
            schemaObject[propertyName] = Yup.object()
              .required()
              .label(labelName);
          }

          if (element.type === "radio_button") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .label(labelName);
          }
          if (element.type === "slider") {
            schemaObject[propertyName] = Yup.string()
              .required()
              .label(labelName);
          }
          if(element.type == "button"){
            console.log("element",element)
            schemaObject[propertyName] = Yup.string()
              .required()               
              .label(labelName);
             
            
          }
          if(element.type == "sign_off"){
            schemaObject[propertyName] = Yup.string()
              .trim()
              .required()
              .label(labelName);          
          }
          

        }
        //}
      });
    });
    const schema = Yup.object().shape(schemaObject);
    return schema;
  };

  const updateSheetData = async (values: any) => {
    const newTemplateData = [templateData, data.template[1]];
    const formData = {
      template: newTemplateData,
      active_step: tempSteps + 1,
    };
    console.log("formData", formData);

    updateSurveyTemplateAPI(survey_id, formData)
      .then((response: any) => {
        setActiveStep(activeStep + 1);
      })
      .catch((error: any) => {
        console.log("error", error);
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  const handleSubmit = async (values: any) => {
    //check templateData if active step is sign_off then check field.signature is not empty
    let isSignEmpty = false;
    let isMinLength =0;
    let imageName = ''
    const currentTemplate = templateData[activeStep - 1];
     currentTemplate.subsections.forEach((subItem: any) => {
      subItem.fields.forEach((element: any) => {
        if (element.type === "sign_off") {
            if(!element.signature || element.signature == ""){
            
              isSignEmpty = true
              
            }
        }
           if(element.type === "button" && element.visibility && element.require && element.min_length  && element.image_set.length<element.min_length ){
         
          isMinLength = element.min_length
          imageName = element.title;
          
        }
      });
    });
    if(isMinLength > 0){
      toast.error(`Please upload minimum ${isMinLength} images in ${imageName}`);
          return;
    }
    if(isSignEmpty){
      toast.error("Please sign off before proceeding");
      return;
    }
    setBtnLoading(true);
   
    setTempSteps(activeStep + 1);
    await updateSheetData(values);
  };

  const handleSectionControl = (index: number) => {
    console.log("index", index);
    if (index < tempSteps) {
      setActiveStep(index + 1);
    }
  };
  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column h-100 pageContainer px-sm-0 border-left w-100 pb-sm-1">
          <div className="pageHeader position-relative px-4 py-3  text-white survery-process-bg d-flex align-items-center justify-content-between">
            <div className="survery-title">               
              <div className="fs-20 fw-bold">Survey Template</div>
              <div className="fs-14 opacity-7 fw-normal">
                last edited by {data?.updatedBy?.full_name} at{" "}
                {moment(data.updated_at).format("DD MMM YYYY hh:mm A")}
              </div>
            </div>

            <div className="fs-18 position-absolute text-center text-uppercase w-100 fw-bold lt-30 left-0 survery-process-top-text">
              Survey Process
            </div>

            <div className="fs-14">
              Page {activeStep} of {data.template[0].length + 1}{" "}
            </div>
          </div>
          <div className="flex-grow-1 overflow-auto pageContent">
            <Row className="gx-0 min-h-100">
              <div className="col-md-3 card ">
                <ul className="p-3 survery-list">
                  {data.template[0].map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        <a
                          href="javascript:void(0)"
                          className={index === activeStep - 1 ? "active" : ""}
                          onClick={() => {
                            handleSectionControl(index);
                          }}
                        >
                          {item.title}
                          {item.sub_title && (
                            <span className="fs-12 control-label fw-normarl w-100 d-block">
                              ({item.sub_title})
                            </span>
                          )}
                        </a>
                      </li>
                    );
                  })}
                  <li>
                    <a
                      href="javascript:void(0)"
                      className={
                        activeStep > data.template[0].length ? "active" : ""
                      }
                      onClick={() => {
                        handleSectionControl(data.template[0].length);
                      }}
                    >
                      Compare and Finish
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-9 pt-4">
                {activeStep <= data.template[0].length && (
                  <div className="max-width-700 mx-auto survery-middle-content">
                    {templateData.map((item: any, index: number) => {
                      if (index === activeStep - 1) {
                        if (item.subsections.length === 0) {
                          return (
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <h1>No Fields Found</h1>
                            </div>
                          );
                        }

                        return (
                          <div key={index}>
                            {/* fields and sections will be here  */}
                            <Formik
                              validationSchema={getValidationSchema(
                                item.subsections
                              )}
                              initialValues={getInitialValues(item.subsections)}
                              onSubmit={handleSubmit}
                              enableReinitialize={true}
                            >
                              {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                                setFieldError,
                                setFieldValue,
                              }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                  {item.subsections?.map(
                                    (subItem: any, subIndex: number) => {
                                      return (
                                        <>
                                          <h3
                                            key={subIndex}
                                            className="fs-20 fw-bold info-text mb-3"
                                          >
                                            {" "}
                                            {subItem.title}
                                          </h3>

                                          {subItem.fields?.map(
                                            (
                                              field: any,
                                              fieldIndex: number
                                            ) => {
                                              return (
                                                // field type text
                                                <>
                                                  {field.visibility &&  field.type === "text" && (
                                                    <TypeText
                                                      field={field}
                                                      index={index}
                                                      fieldIndex={fieldIndex}
                                                      subIndex={subIndex}
                                                      touched={touched}
                                                      errors={errors}
                                                      values={values}
                                                      templateData={
                                                        templateData
                                                      }
                                                      setTemplateData={
                                                        setTemplateData
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      fieldConditions={conditions}
                                                      handleBlur={handleBlur}
                                                    />
                                                  )}

                                                  {/* field type message */}
                                                  {field.visibility && field.type === "message" && (
                                                    <p className="fs-14 mb-2 px-3 ">
                                                      {field.default_value
                                                        ? field.default_value
                                                        : "No default value"}
                                                    </p>
                                                  )}

                                                  {/* field type dropdown  */}
                                                  {field.visibility &&  field.type ===
                                                    "dropdown" && (
                                                    <TypeDropdown
                                                      field={field}
                                                      index={index}
                                                      fieldIndex={fieldIndex}
                                                      subIndex={subIndex}
                                                      touched={touched}
                                                      errors={errors}
                                                      values={values}
                                                      templateData={
                                                        templateData
                                                      }
                                                      setTemplateData={
                                                        setTemplateData
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      handleBlur={handleBlur}
                                                      fieldConditions={conditions}

                                                    />
                                                  )}

                                                  {/* field type medio upload button */}
                                                  {field.visibility && field.type === "button" && (
                                                   <>  
                                                    <TypeMediaUpload
                                                      field={field}
                                                      index={index}
                                                      fieldIndex={fieldIndex}
                                                      subIndex={subIndex}
                                                      touched={touched}
                                                      errors={errors}
                                                      values={values}
                                                      templateData={
                                                        templateData
                                                      }
                                                      setTemplateData={
                                                        setTemplateData
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      
                                                      handleBlur={handleBlur}
                                                      survey_id={survey_id}
                                                    /></>
                                                  )}

                                                  {/* field type range slider */}
                                                  {field.visibility &&  field.type === "slider" && (
                                                    <TypeRangeSlider
                                                      field={field}
                                                      index={index}
                                                      fieldIndex={fieldIndex}
                                                      subIndex={subIndex}
                                                      touched={touched}
                                                      errors={errors}
                                                      values={values}
                                                      templateData={
                                                        templateData
                                                      }
                                                      setTemplateData={
                                                        setTemplateData
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      handleBlur={handleBlur}
                                                      survey_id={survey_id}
                                                      fieldConditions={conditions}

                                                    />
                                                  )}

                                                  {/* field type radio button */}

                                                  {field.visibility &&  field.type ===
                                                    "radio_button" && (
                                                    <TypeRadioButton
                                                      field={field}
                                                      index={index}
                                                      fieldIndex={fieldIndex}
                                                      subIndex={subIndex}
                                                      touched={touched}
                                                      errors={errors}
                                                      values={values}
                                                      templateData={
                                                        templateData
                                                      }
                                                      setTemplateData={
                                                        setTemplateData
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      handleBlur={handleBlur}
                                                      survey_id={survey_id}
                                                                                                            fieldConditions={conditions}

                                                    />
                                                  )}
                                                  {/* type sign off */}
                                                  {field.type == "sign_off" && (
                                                    <SignOff
                                                      field={field}
                                                      index={index}
                                                      fieldIndex={fieldIndex}
                                                      subIndex={subIndex}
                                                      touched={touched}
                                                      errors={errors}
                                                      values={values}
                                                      templateData={
                                                        templateData
                                                      }
                                                      setTemplateData={
                                                        setTemplateData
                                                      }
                                                      setFieldValue={
                                                        setFieldValue
                                                      }
                                                      handleBlur={handleBlur}
                                                      survey_id={survey_id}
                                                      setFieldError={setFieldError}
                                                    />
                                                  )}
                                                </>
                                              );
                                            }
                                          )}
                                          <div className="d-flex justify-content-between mt-3 border-top pt-3 survery-bottom-button">
                                            <div
                                              className={(activeStep > 1) ? "btn btn-outline-info  me-3 fs-14 " : "btn btn-outline-info  me-3 fs-14 disabled"}
                                              onClick={() => (activeStep > 1) ? handlePrevious() : false}
                                            >
                                              <KeyboardArrowLeftIcon className="me-1 fs-18" />
                                              Previous Page
                                            </div>
                                            {btnLoading ? (
                                              <button
                                                className="btn btn-primary text-white fs-14"
                                                disabled
                                              >
                                                <Loader
                                                  isLoading={btnLoading}
                                                />
                                              </button>
                                            ) : (
                                              <button
                                                type="submit"
                                                className="btn btn-primary text-white fs-14"
                                              >
                                                Next Page{" "}
                                                <KeyboardArrowRightIcon className="ms-1 fs-18" />
                                              </button>
                                            )}
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                                </Form>
                              )}
                            </Formik>
                          </div>
                        );
                      }
                    })}
                  </div>
                )}

                 {activeStep > data.template[0].length && (
                  <CompareAndFinish
                    survey_id={survey_id}
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    contract_id={data.survey.contract_id}
                  />
                )}
              </div>
            </Row>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default StartSurvey;
