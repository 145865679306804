import { Route, Routes } from "react-router-dom";
import AddEmployee from "./Add";
import EmployeeList from "./index";
import ArchiveEmployeeList from  "./ArchiveEmployeeList"
import ViewEmplopyee from './View/index';
import SendEmail from './View/SendEmail';
import ViewSentEmail from './View/ViewSentEmail';
import Hmrc from "./View/Hmrc";
import Medical from "./View/Medical";
import { checkAbility } from "../../utility/common";
import { PERMISSION_ACCESS, PERMISSION_MODULE } from "../../constants/permissionUser";
import { Login } from "../Auth/Login";
import MyDocuments from "./My-Documents";

export const Employees = () => {
  return (
    <Routes>
     {/* // {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.ALL_EMPLOYEE) && ( */}
        <Route path="/documents/:id?" element={<MyDocuments />} />
     {/* // )} */}
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Route path="/" element={<EmployeeList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.LIST, PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Route path="/archive-employees" element={<ArchiveEmployeeList />} />
      )}
      {checkAbility(PERMISSION_ACCESS.ADD,PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Route path="/add-employee" element={<AddEmployee />} />
      )}
      {checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Route path="/edit-employee/:id" element={<AddEmployee />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Route path="/view-employee/:id/:tabKey?" element={<ViewEmplopyee />} />
      )}
      {(checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.ALL_EMPLOYEE) || checkAbility(PERMISSION_ACCESS.EDIT, PERMISSION_MODULE.MY_TEAM)) && (
        <Route path="/send-email/:id/:meeting_id?" element={<SendEmail />} />
      )}
      {checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.ALL_EMPLOYEE) && (
        <Route path="/sent-email-detail/:id" element={<ViewSentEmail />} />
      )}
      {(checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.ALL_EMPLOYEE) || checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.MY_TEAM)) && checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.HMRC_FORM) && (
        <Route path="/hmrc/:id" element={<Hmrc />} />
      )}
      {(checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.ALL_EMPLOYEE) || checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.MY_TEAM)) && checkAbility(PERMISSION_ACCESS.VIEW, PERMISSION_MODULE.MEDICAL_QUESTIONNAIRE) && (
        <Route path="/medical/:id" element={<Medical />} />
      )}

      <Route path="*" element={<Login />} />

    </Routes>
  );
};
